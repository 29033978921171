import React from "react"
import { graphql } from "gatsby"
import { Section, Container, Form } from "../components/utils"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaPhone, FaEnvelope } from "react-icons/fa"
import styled from "styled-components"

const ContactPage = ({ data, location }) => {
  const {
    title,
    image,
    intro,
    emailAddress,
    phoneNumber,
    socialMediaProfiles,
  } = data.page

  return (
    <Layout>
      <Hero image={image.fluid.src}>
        <Container>
          <h1>{title}</h1>
        </Container>
      </Hero>
      <Section padding="medium" topOnly>
        <Container>
          <Row>
            <div>
              <div
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: intro }}
              />
              <h3 className="no-line mb-1">
                <FaEnvelope className="text-orange contact-icon" />
                {emailAddress}
              </h3>
              <h3 className="no-line mb-1">
                <FaPhone className="text-orange contact-icon" />
                {phoneNumber}
              </h3>
              {socialMediaProfiles.map(({ iconSvgCode, handle, link }) => (
                <>
                  <a href={link}>
                    <h3 className="no-line mb-1">
                      {iconSvgCode && (
                        <Icon
                          dangerouslySetInnerHTML={{ __html: iconSvgCode }}
                        />
                      )}
                      {handle}
                    </h3>
                  </a>
                </>
              ))}
            </div>
            {location.search === "?success=true" ? (
              <div>
                <h2 className="text-orange">Form Submitted!</h2>
                <p>We'll be in touch shortly.</p>
              </div>
            ) : (
              <Form inverse={true} name="Contact Page" />
            )}
          </Row>
        </Container>
      </Section>
      {/* <ContactSection /> */}
      <SEO title="Contact Us" />
    </Layout>
  )
}

export default ContactPage

const Icon = styled.span`
  height: 20px;
  float: left;
  margin-right: 10px;
  svg {
    fill: ${props => props.theme.orange};
    height: 100%;
  }
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1rem;
  & > * {
    flex: 1 1 100%;
    margin: 1rem;
    @media (min-width: 850px) {
      flex: 1 1 300px;
    }
  }
`
const Hero = styled.div`
  min-height: 300px;
  padding: 2rem 0;
  overflow: hidden;
  background: linear-gradient(rgba(32, 58, 112, 0.7), rgba(32, 58, 112, 0.7)),
    url(${props => props.image});
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  display: flex;
  align-items: center;
  text-align: center;
  h1 {
    color: white;
  }
`

export const query = graphql`
  {
    page: datoCmsContactPage {
      title
      image {
        fluid(maxWidth: 1000) {
          src
        }
      }
      intro
      emailAddress
      phoneNumber
      socialMediaProfiles {
        iconSvgCode
        handle
        link
      }
    }
  }
`
